<template>
  <v-card flat>
    <v-form v-model="valid">
      <template v-for="field in fieldsWithValues">
        <v-col
          v-if="field.show"
          :key="field.key"
          :cols="field.cols ? fields.cols : 12"
        >
          <UiField
            :value="field"
            in-col
            @change="setFieldValue"
          />
        </v-col>
      </template>
      <v-col cols="12" class="mb-0 pb-0">
        <v-select
          :label="$t('categories')"
          v-model="value.categories"
          item-value="uuid"
          item-text="label"
          :items="categories"
          multiple
          chips
          deletable-chips
          small-chips
          filled
          :disabled="processing"
        />
      </v-col>

      <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
        <v-select
          :label="$t('printers')"
          v-model="value.printers"
          item-value="uuid"
          item-text="label"
          :items="printers"
          multiple
          chips
          deletable-chips
          small-chips
          filled
          :disabled="processing"
        />
      </v-col>

      <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
        <v-select
          :label="$t('onlineChannels')"
          v-model="value.channels"
          item-value="uuid"
          item-text="label"
          :items="onlineChannels"
          multiple
          chips
          deletable-chips
          small-chips
          filled
          :disabled="processing"
        />
      </v-col>
      <v-col cols="12" class="mt-0 mb-0 pt-0 pb-0">
        <v-select
          :label="$t('posChannels')"
          v-model="value.posChannels"
          item-value="uuid"
          item-text="label"
          :items="posChannels"
          chips
          multiple
          deletable-chips
          small-chips
          filled
          :disabled="processing"
        />
      </v-col>
    </v-form>
  </v-card>
</template>

<script>

import UiField from "@/components/uiField"

export default {
  name: 'ProcessDisplaySystemForm',
  components: { UiField },
  props: ['value'],
  data() {
    return {
      processing: false,
      processDisplaySystem: null,
      fieldsWithValues: null,
      selectedColorField: {
        key: null,
        value: null
      },
      valid: false,
    }
  },
  mounted() {
    this.processDisplaySystem = this.value
    if( typeof this.processDisplaySystem !== 'undefined' && this.processDisplaySystem !== null ) {
      this.setFieldValues()
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'title',
          show: true,
          value: null,
          type: 'text',
          label: this.$t('title'),
          required: true,
          rules: [this.rules.required],
          editable:  true,
          clearable: true
        },
        {
          key: 'location',
          show: true,
          value: null,
          type: 'select',
          label: this.$t('location'),
          required: true,
          rules: [this.rules.required],
          multiple: false,
          options: this.locations
        },
        // {
        //   key: 'categories',
        //   show: true,
        //   value: null,
        //   type: 'select',
        //   label: this.$t('categories'),
        //   multiple: true,
        //   options: this.categories
        // },
        {
          key: 'states',
          show: true,
          value: null,
          type: 'select',
          label: this.$t('states'),
          multiple: true,
          options: this.states
        },
      ]
    },
    states() {
      return [
        'new',
        'pending',
        'processing',
        'completed'
      ]
    },
    rules() {
      return this.$store.getters.rules
    },
    categories() {
      return this.$store.state.categories.map(c => {
        return {
          uuid: c.uuid,
          label: c.name
        }
      })
    },
    channels() {
      return this.$store.state.channels.map(c => {
        return {
          uuid: c.uuid,
          label: c.label
        }
      })
    },
    posChannels() {
      if(Array.isArray(this.channels)) {
        if(Array.isArray(this.processDisplaySystem.channels)) {
          return this.channels.map(c => {
            return {
              uuid: c.uuid,
              label: c.label,
              disabled: (this.processDisplaySystem.channels.some(oc => oc && oc.uuid === c.uuid) && !this.processDisplaySystem.posChannels.some(pc => pc && pc.uuid === c.uuid))
            }
          })
        }
        return this.channels
      }
      return []
    },
    onlineChannels() {
      if(Array.isArray(this.channels)) {
        if(Array.isArray(this.processDisplaySystem.posChannels)) {
          return this.channels.map(c => {
            return {
              uuid: c.uuid,
              label: c.label,
              disabled: (this.processDisplaySystem.posChannels.some(pc => pc && pc.uuid === c.uuid) && !this.processDisplaySystem.channels.some(oc => oc && oc.uuid === c.uuid))
            }
          })
        }
        return this.channels
      }
      return []
    },
    locations() {
      const locations = Array.isArray(this.$store.state.locations) ? this.$store.state.locations.filter(location => location && location.active !== false) : []
      return this.$store.getters.locationsFormatForUiFields(locations)
    },
    printers(){
      return this.$store.state.printers.map(p => {
        return {
          uuid: p.uuid,
          label: p.title
        }
      })
    }
  },
  methods: {
    setFieldValue({ field, value }) { 
      this.processDisplaySystem[field.key] = value
    },
    setFieldValues() {
      const newFields = []
      for(const index in this.fields) {
        const field = this.fields[index]
        if(field && field.key) {
          if(typeof this.processDisplaySystem[field.key] !== 'undefined') {
            field.value = this.processDisplaySystem[field.key]
            this.fields[index].value = this.processDisplaySystem[field.key]
          }
        }
        newFields.push(field)
      }
      this.fieldsWithValues = newFields
    },
  },
  watch: {
    valid(value) {
      this.$emit('valid', value)
    },
  }
}
</script>
